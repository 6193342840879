export default {
    locale: {
        current_language_name: 'Français',
    },
    pages: {
        blocklist: {
            title: 'Liste des Blocs',
            display_empty_blocks: 'Afficher les blocs vides',
        },
        blockpage: {
            block: 'Bloc',
            overview: 'Vue d\'ensemble',
            transactions: 'Transactions',
        },
        transactions: {
            contract_deployment: 'Déploiement de contrat',
            deposit_action_name: 'dépôt',
            deposit_native: 'dépôt (native)',
            five_hundred_k_disclaimer: 'Affichage des derniers 500K enregistrements de { total }',
            for_block: 'pour le bloc',
            for: 'pour',
            gas_info_label: 'Infos sur le gaz',
            native_deposit_tooltip: 'Dépôt depuis le Telos natif',
            native_withdraw_tooltip: 'Retrait vers le Telos natif',
            nonce_label: 'Nonce',
            see_more_details: 'Voir plus de détails',
            see_tx_preview_tooltip: 'Voir un aperçu des détails de la transaction',
            status_label: 'Statut',
            to: 'à',
            transaction_action_label: 'Action de transaction',
            transaction_fee_label: 'Frais de transaction',
            transactions: 'Transactions',
            transfer_for_x_tlos_from: 'Transfert de { amount } TLOS depuis',
            transfer_tlos_action_name: 'Transférer { symbol }',
            withdraw_action_name: 'retrait',
            withdraw_native: 'retrait (natif)',
            x_gas_used_of_y_limit: '{ amount } gaz utilisé sur une limite de { limit }',
            see_all_transactions: 'Voir toutes les transactions',
        },
        transaction: {
            page_title: 'Détails de la transaction',
            overview: 'Aperçu',
            logs: 'Journaux',
            internal: 'Transactions internes',
            not_found: 'Transaction non trouvée',
        },
        internaltrx: {
            page_title: 'Transactions internes du contrat',
            for_address: 'pour l\'adresse { address }',
        },
        home: {
            telos_evm_explorer: 'L\'explorateur Telos EVM',
            market_cap: 'Capitalisation boursière',
            last_finalized_block: 'Dernier bloc finalisé',
            total_transactions: 'Nombre total de transactions',
            latest_blocks: 'Derniers Blocs',
            view_all_blocks: 'Voir tous les blocs',
            latest_transactions: 'Dernières Transactions',
            view_all_transactions: 'Voir toutes les transactions',
        },
        error: {
            title: 'Page non trouvée.',
            subtitle: 'Veuillez vérifier à nouveau l\'URL. Si elle semble correcte, réessayez plus tard.',
        },
        issuer: 'Émetteur',
        account_not_found: 'Nous n\'avons pas pu trouver ce compte',
        approvals: 'Permissions',
        supported_interfaces: 'Interface(s) supporté(s) connue(s)',
        explore_transactions: 'Explorer les transactions',
        recent_transactions: 'Transactions récentes',
        rpc_endpoints: 'RPC',
        monitor: 'Moniteur',
        go_home: 'Accueil',
        minted: 'Frappé(s)',
        telos_supply: 'Réserve Telos EVM',
        holders: 'Détenteurs',
        evm_holders: 'Tout détenteurs sur Telos EVM',
        total_nfts_minted: 'Total des NFTs crés dans cette collection',
        select_sol_file: 'Sélectionnez votre fichier .sol pour l\'envoyer',
        select_json_file: 'Sélectionnez un fichier avec un objet JSON standard à envoyer',
        paste_contract_contents: 'vous devez séléctionner un fichier à envoyer ou utiliser le boutton de bascule pour pouvoir coller le contenu de votre contrat',
        contract_address: 'Adresse du contrat',
        enter_contract_address: 'Veuillez saisir une adresse valide, ex: \'0x0123...\'',
        invalid_address_format: 'Format de l\'adresse invalide',
        compiler_version: 'Version du compiler',
        select_compiler_version: 'sélectionner la version du compiler',
        eg_contracts: 'e.g., \'contrats/\'',
        contract_file_directory_path: 'Chemin des fichiers du contrat (laissez vide si non applicable)',
        invalid_path_format: 'le chemin doit finir avec une barre oblique /',
        upload_file: 'envoyer le fichier',
        text_input: 'entrée texte',
        runs_value_for_optimization: 'Nombre d\'itération d\'optimisation',
        constructor_arguments: 'Arguments du constructeur',
        comma_seperated_values: 'valeurs séparées par une virgule ex: Bob,123,0x12345...',
        no_trailing_commas: 'pas de virgule finale',
        paste_contract_code_here: 'copier/coller le code du contract ici...',
        enter_contract_text: 'Entrer ou coller le texte du contat',
        verify_contract: 'Verifier le contrat',
        reset: 'Réinitialiser',
        gas_used: 'Gaz utilisé',
        account: 'Compte',
        contract: 'Contrat',
        transfers_title: 'Transferts { type }:',
        loading_transfers: 'Chargement des transferts',
        loading_approvals: 'Chargement des permissions',
        erc20_transfers: 'Transferts ERC20',
        erc20_transfers_tooltip: 'Liste des transferts de jetons ERC20 dans cette transaction',
        erc721_transfers: 'Transferts ERC721',
        erc721_transfers_tooltip: 'Liste des transferts de jetons ERC721 dans cette transaction',
        erc1155_transfers: 'Transferts ERC1155',
        erc1155_transfers_tooltip: 'Liste des transferts de jetons ERC1155 dans cette transaction',
        tlos_transfers: '{ symbol } transfers',
        tlos_transfers_tooltip: 'Liste des transferts internes de { symbol } dans cette transaction',
        tokens: 'Jetons',
        created_at_trx: 'Création',
        by_address: 'Par',
        number_used_once: 'Nombre utilisé une fois (nonce)',
        native_account: 'Compte natif',
        balance: 'Solde',
        view_source_prompt: 'Ce contrat à été vérifié. Vous pouvez consulter son code source et metadonnées dans l\'onglet \'contrat\'',
        account_url: '{ domain }/account/{ account }',
        tlos_balance: '{ balance } { symbol }',
        couldnt_retreive_metadata_for_address: 'Impossible de récupérer les metadonnées pour { address }: { message }',
        transaction_details: 'Détails de la transaction',
        transaction_not_found: 'Non trouvé: { hash }',
        general: 'Général',
        details: 'Détails',
        logs: 'Logs',
        internal_txns: 'Transactions Internes',
        transaction_hash: 'Hash de la transaction ',
        click_to_change_date_format: 'Cliquer pour changer le format de la date',
        block_number: 'Numéro de bloc',
        from: 'Expéditeur',
        to: 'Destination',
        date: 'Date',
        success: 'Succès',
        failure: 'Échec',
        status: 'Statut',
        balance_gwei: '{ amount } GWEI',
        balance_tlos: '{ amount } TLOS',
        error_message: 'Message d\'Erreur',
        contract_function: 'Fonction appelée',
        function_parameters: 'Paramètres de la fonction',
        deployed_contract: 'Contrat Déployé',
        click_to_show_in_wei: 'Cliquez pour voir en wei',
        gas_price_charged: 'Prix du gaz',
        gas_fee: 'Frais de gaz',
        gas_limit: 'Limite de gaz',
        nonce: 'Nonce',
        input: 'Entrée',
        output: 'Sortie',
        value: 'Valeur',
        count: 'Montant',
        overview: 'aperçu',
        more_info: 'plus d\'info',
        transaction_sent: 'txn envoyé',
        no_transactions_yet: 'Aucune transaction pour le moment',
        first: 'premier',
        last: 'dernier',
        contract_creator: 'créateur de contrat',
    },
    components: {
        gas_limit_tooltip: 'La quantité maximale de gaz pouvant être dépensée pour cette transaction',
        gas_used_tooltip: 'La quantité de gaz qui a été dépensée pour cette transaction',
        known_tokens: 'Tokens connus',
        other_tokens: 'Tokens inconnus',
        no_balances_found: 'Aucun solde de token ERC20 trouvé pour cette adresse',
        internal_txns: 'Transactions internes',
        n_internal_txns: '{ amount } transactions internes',
        none: 'Aucun',
        verify_prompt: 'Ce contrat n\'a pas été vérifié. Souhaitez-vous télécharger le(s) contrat(s) et les métadonnées pour vérifier la source maintenant?',
        verify_contract: 'Vérifier le contrat',
        search_evm_address_failed: 'La recherche de l\'adresse EVM liée au compte natif { accountName } a échoué. Vous pouvez en créer une sur wallet.telos.net',
        unknown_web3_login_type: 'Type de connexion web3 inconnu: { provider }',
        unknown_evm_login_provider: 'Fournisseur de connexion EVM inconnu: { provider }',
        unknown_native_login_provider: 'Fournisseur de connexion natif inconnu: { provider }',
        connect_wallet: 'Connecter le portefeuille',
        view_address: 'Voir l\'adresse',
        disconnect: 'Déconnecter',
        disable_wallet_extensions: 'Désactivez les portefeuilles d\'extension ou définissez Brave Wallet comme portefeuille par défaut dans les paramètres de portefeuille du navigateur pour utiliser Brave Wallet',
        enable_wallet_extensions: 'Activez l\'extension MetaMask et définissez le paramètre par défaut sur `prefer extensions` dans les paramètres de portefeuille du navigateur pour utiliser le portefeuille MetaMask',
        evm_wallets: 'Portefeuilles EVM',
        advanced: 'Avancé',
        continue_on_metamask: 'Continuer sur Metamask',
        text1_native_wallets: 'Portefeuilles natifs pour',
        text2_advanced_users: 'utilisateurs avancés',
        text3_or_to_recover_assets: 'ou pour récupérer des actifs envoyés à une adresse liée native',
        copy_to_clipboard: 'Copier { text } dans le presse-papiers',
        latest_block: 'Dernier bloc',
        price_sources: 'Données obtenues de Coingecko, CoinMarketCap ou, si indisponible, des échanges décentralisés sur Telos EVM',
        marketcap_sources: 'Données obtenues de Coingecko ou CoinMarketCap',
        usd_marketcap: 'Capitalisation en USD',
        usd_price: 'Prix en USD',
        usd_value: 'Valeur en USD',
        tlos_price: 'Prix de TLOS',
        gas_price: 'Prix du gaz',
        gas_price_tlos: '(Prix du gaz * Gaz utilisé) en TLOS',
        gas_price_gwei: 'Prix du gaz en gwei',
        click_to_expand: 'Cliquez pour développer',
        click_to_fold: 'Cliquez pour replier',
        expand_all: 'Développer tout',
        collapse_all: 'Réduire tout',
        search_evm_failed: 'La recherche de l\'adresse EVM liée au compte natif { search_term } a échoué.',
        search_failed: 'La recherche a échoué, veuillez entrer un terme de recherche valide.',
        add_to_metamask: 'Ajouter { symbol } à MetaMask',
        tx_hash: 'Hash de la transaction',
        direction: 'Direction',
        block: 'Bloc',
        date: 'Date et heure (UTC)',
        age: 'Âge',
        method: 'Méthode',
        to_interacted_with: 'Pour',
        value: 'Valeur',
        txn_fee: 'Frais Txn',
        txn_failed: 'Txn Échouée',
        token: 'Jeton',
        unknown_precision: 'Précision inconnue',
        click_to_change_format: 'Clicquez pour changer le format',
        balance: 'Solde',
        error_fetching_balance: 'erreur lors de l\'obtention du solde',
        launch_metamask_dialog_to_add: 'Démarrer le dialogue Metamask pour ajouter{ symbol }',
        search_hints: 'Transaction,Adresse,Bloc',
        no_provider_found: 'Plus d\'un fournisseur de portefeuille est actif, désactivez les fournisseurs en trop. Si cela ne fonctionne toujours pas votre portefeuille EVM n\'est peut être pas supporté.',
        copied: 'Copié',
        copy_to_clipboard_failed: 'La copie sur le presse-papiers à échoué',
        gwei: 'Gwei',
        failed_to_fetch_transactions: 'Erreur lors du chargement des transactions.',
        failed_to_parse_transaction: 'Echec de l\'analyse des données de la transaction. Erreur: { message }',
        executed_based_on_decoded_data: 'Fonction executé à partir des données d\'entrée décodé. Pour les fonctions non identifiés, l\'ID de méthode est affiché à la place.',
        unsupported_token_type: 'Type de jeton non supporté: { tokenType }',
        token_id: 'Id #{ tokenId }',
        status: 'Statut',
        nonce: 'Nonce',
        from: 'De',
        to: 'À',
        item: 'Élément',
        amount: 'Quantité',
        download_image: 'Télécharger l’image',
        confirm_download_image: 'Confirmez que vous voulez télécharger cette image',
        confirm: 'Confirmer',
        click_to_toggle_media_size: 'Cliquez pour changer la taille du média',
        toggle_expand: 'Basculer pour s\'adapter à l\'écran ou taille originale',
        close: 'Fermer',
        input_viewer: {
            name: 'Nom',
            type: 'Type',
            data: 'Données',
            default_view: 'Vue par défaut',
            original_view: 'Vue originale',
            decoded_view: 'Vue décodée',
            copy_button_desc: 'tableau des paramètres',
        },
        approvals: {
            token_id: 'ID Jeton',
            approved: 'Approuvé',
            approvals_granted_title: 'Approbations ERC :',
            login_account: 'Veuillez vous connecter à cette addresse à l\'aide de votre portefeuille afin de consulter ses permissions.',
            approval_text: 'Nous allons définir le montant des permissions séléctionés à 0. Ceci requiert une signature avec votre portefeuille.',
            type: 'Type',
            delete: 'Supprimer',
            delete_all: 'Supprimer tout',
            unselect_all: 'Tout déséléctionner',
            cancel: 'Annuler',
            remove_approval: 'Supprimer',
            removal_approval: 'Supprimer la permission',
            unselect_all_approvals: 'Déséléctionner les permissions',
            infinite: 'Infini',
            infinite_tooltip: 'Permission plus grande que la totalité des jetons en existence',
            select: 'Selectionnez la permission',
            unselect: 'Déséléctionner la permission',
            update: 'Mise à jour permission(s)',
            update_description: 'Saisissez le nouveau montant de la permission. Ceci requiert une signature avec votre portefeuille.',
            removal_approvals: 'Supprimer toutes les permissions',
            removal_selected_approvals: 'Supprimer toutes les permissions séléctionnées',
            update_failed: 'Echec lors de la mise à jour de la ou des permissions',
            update_success: 'Permission à {spender} pour {contract} a bien été mise à jour',
            spender: 'Dépensier',
            amount: 'Montant',
        },
        nfts: {
            show_without_metadata: 'Afficher les NFTs sans métadonnées',
            id: 'ID du Jeton',
            amount: 'Quantité',
            name: 'Nom',
            collection: 'Collection',
            nfts: 'NFTs',
            metadata: 'Meta',
            minter: 'Créateur',
            owner: 'Propriétaire',
            contract: 'Contrat',
            image: 'Image',
            media: 'Médias',
            minted: 'Bloc frappé',
            attributes: 'attribut',
            ipfs: 'Obtenir depuis IPFS',
            consult_metadata: 'Consulter les métadonnées',
            consult_media: 'Consulter les médias',
            consult_collection: 'Consulter la collection',
        },
        holders : {
            show_system_contracts: 'Voir les contrats systèmes',
            holder: 'Détenteur',
            balance: 'Solde',
            telos_supply: 'Parts Telos EVM',
            global_supply: 'Parts globales',
            updated: 'Mis à jour',
        },
        transaction: {
            in: 'entre',
            out: 'sort',
            self: 'reste',
            load_error: 'Impossible de charger les transactions',
            show_short: 'Afficher court',
            show_total: 'Afficher total',
            show_wei: 'Afficher wei',
            value_uint256: 'Valeur (uint256) : ',
            tlos_transfer: '{ symbol } Transfer',
            contract_deployed: 'Contrat Deployé',
            native_deposit: 'Dépôt de Telos Natif',
            native_withdraw: 'Transfert à Telos Natif',
            unknown: 'Inconnu',
            contract_deployment: 'Déploiement de Contrat',
            contract_interaction: 'Interaction de Contrat',
            deposit: 'dépôt',
            withdraw: 'retrait',
            no_internal_trxs_found: 'Aucune transaction interne trouvée',
            human_readable: 'Lisible par Humain',
            no_logs_found: 'Aucun journal trouvé',
            verify_related_contract: 'Vérifiez le contrat associé pour chaque journal pour voir sa version lisible par humain',
            failed_to_retrieve_contract: 'Échec de la récupération du contrat avec l\'adresse { address }',
            trx_hash: 'Hash de la Transaction',
            trx_hash_tooltip: 'L\'identifiant unique de la transaction.',
            block: 'Bloc',
            block_tooltip: 'Le numéro du bloc dans lequel cette transaction a été incluse.',
            status: 'Statut',
            status_tooltip: 'Indique si la transaction a réussi, échoué ou est en attente',
            timestamp: 'Horodatage',
            timestamp_tooltip: 'L\'heure et la date à laquelle la transaction a été confirmée.',
            trx_action: 'Action de la Transaction',
            trx_action_tooltip: 'L\'opération effectuée par cette transaction, telle qu\'un transfert ou une exécution de contrat.',
            from: 'De',
            from_tooltip: 'L\'adresse initiant la transaction.',
            to: 'À',
            to_tooltip: 'L\'adresse de destination de la transaction.',
            erc20_transfers: 'Transferts ERC20',
            erc20_transfers_tooltip: 'Liste des transferts de tokens ERC20 dans cette transaction',
            erc721_transfers: 'Transferts ERC721',
            erc721_transfers_tooltip: 'Liste des transferts de tokens ERC721 dans cette transaction',
            erc1155_transfers: 'Transferts ERC1155',
            erc1155_transfers_tooltip: 'Liste des transferts de tokens ERC1155 dans cette transaction',
            tlos_transfers: 'Transferts TLOS',
            tlos_transfers_tooltip: 'Liste des transferts internes de TLOS dans cette transaction',
            value: 'Valeur',
            value_tooltip: 'Le montant de la cryptomonnaie transférée dans cette transaction.',
            gas_fee: 'Frais de Transaction',
            gas_fee_tooltip: 'Le total des frais payés pour cette transaction, en TLOS et en USD.',
            gas_price: 'Prix du Gaz',
            gas_price_tooltip: 'Le prix par unité de gaz payé pour la transaction.',
            more_details: 'Plus de Détails',
            show_more_details: 'cliquez pour montrer plus',
            show_less_details: 'cliquez pour montrer moins',
            gas_limit_n_usage: 'Limite de Gaz et Utilisation par Txn',
            gas_limit_n_usage_tooltip: 'La quantité maximale de gaz autorisée pour la transaction et la quantité réelle de gaz utilisée.',
            nonce: 'Nonce',
            nonce_tooltip: 'Un numéro séquentiel associé à chaque transaction effectuée par la même adresse, garantissant que les transactions sont traitées dans l\'ordre.',
            position_in_block: 'Position dans le Bloc',
            position_in_block_tooltip: 'La position de cette transaction dans le bloc.',
            input: 'Entrée',
            input_tooltip: 'Données envoyées avec la transaction, généralement utilisées pour les interactions avec les contrats.',
        },
        inputs: {
            incorrect_address_array_length: 'Le tableau doit contenir { size } adresses',
            incorrect_booleans_array_length: 'Le tableau doit contenir { size } booléens',
            incorrect_bytes_array_length: 'Le tableau doit contenir { size } bytes',
            odd_number_of_bytes: 'Il devrait y avoir un nombre pair de caractères d\'octet',
            incorrect_sigint_array_length: 'Le tableau doit contenir { size } entiers signés',
            incorrect_strings_array_length: 'Le tableau doit contenir { size } chaînes de caractères',
            incorrect_unsigint_array_length: 'Le tableau doit contenir { size } entiers non signés',
            incorrect_values_array_length: 'Il doit y avoir { size } valeurs dans le tableau',
            incorrect_undefined_entry: 'Certaines des entrées ne sont pas dans un format valide',
            incorrect_values_format: 'Certaines des valeurs ne sont pas dans un format valide',
            tuple_struct_input_hint: 'Entrez les valeurs de la structure de tuple entre crochets dans l\'ordre défini dans le contrat',
            invalid_boolean_value: 'La valeur saisie n\'est pas un booléen',
            invalid_address_array_string: 'La valeur saisie ne represente pas un tableau d\'adresses',
            invalid_booleans_array_string: 'La valeur saisie ne represente pas un tableau de booléens',
            invalid_bytes_array_string: 'La valeur saisie ne represente pas un tableau de bytes',
            invalid_sigint_array_string: 'La valeur saisie ne represente pas un tableau d\'entiers signés',
            invalid_strings_array_string: 'La valeur saisie ne represente pas un tableau de chaîne de caractères',
            invalid_unsigint_array_string: 'La valeur saisie ne represente pas un tableau de d\'entiers non signés',
            invalid_address_length: 'Une adresse doit faire exactement 40 caractères, sans compter le "0x"',
            invalid_address_start: 'Une adresse doit débuter par 0x',
            invalid_address_characters: 'Cette entrée contient des caractères invalides',
            readonly: 'Ce champ est en lecture seule',
            required: 'Ce champ est requis',
            too_large: 'La valeur maximum pour int{ size } est { max }',
            too_small: 'La valeur minimum pour int{ size } est { min }',
            too_large_pow2: 'La valeur maximum pour int{ size } est 2^{ size } - 1',
            too_small_pow2: 'La valeur minimum pour int{ size } est -(2^{ size }) + 1',
            too_large_unsigint: 'La valeur maximum pour uint{ size } est 2^{ size } - 1',
            no_negative_unsigint: 'La valeur pour uint{ size } ne peut pas être négative',
            invalid_signed_integer: 'Entier signé non valide',
            invalid_unsigint: 'L\'entrée doit être un entier non signé valide',
            str_input_placeholder: '["une valeur", ... , "valeur final"]',
            str_input_hint: 'Les guillemets dans les chaînes de caractères doivent être échappés (\\")',
            address_placeholder: 'Adresse commençant par 0x',
            address_label: '{ label } (adresse)',
            boolean_array_label: '{ label } (bool[{ size }])',
        },
        health: {
            status: 'Statut',
            checked_at: 'Vérifié à',
            task: 'Tâche',
            message: 'Message',
            category: 'Catégorie',
            block_height: 'Bloc',
            latency: 'Latence',
            success: 'Succès',
            info: 'Info',
            alert: 'Alerte',
            error: 'Erreur',
            click_to_change_format: 'Cliquer pour changer de format',
        },
        contract_tab: {
            abi_loaded_from_interface: 'Cet ABI générique a été chargé à l\'aide de supportsInterface() de l\'IEP-165. Certaines fonctions listées peuvent donc ne pas être implémentés. Vérifiez le contrat pour intégagir avec en utilisant l\'ABI complet.',
            copy_abi_to_clipboard: 'Copier l\'ABI du contrat sur le presse-papiers',
            enter_amount: 'Séléctionnez un nombre de décimales et saisissez un montant qui sera injecté pour vous dans la fonction en tant que uint256',
            result: 'Résultat',
            view_transaction: 'Voir la transaction',
            code: 'Code',
            bytecode: 'Bytecode',
            read: 'Lire',
            write: 'Ecrire',
            amount: 'Montant',
            value: 'Valeur',
            custom_decimals: 'Decimales personalisés',
            custom: 'Personalisé',
            unverified_contract_source: 'Ce contrat n\'est pas vérifié.',
            verified_contract_source: 'Vérifiez et Publiez le code source de votre contrat',
            here: 'ici.',
            upload_source_files: 'pour télécharger les fichiers sources, ce qui vérifiera également ce contrat.',
            abi_autoloaded: 'En attendant, vous pouvez intéragir avec le contrat en utilisant notre ABI générique chargé automatiquement.',
            choose_abi: 'Alternativement, vous pouvez intéragir avec le contrat en utilisant soit un de nos ABIs génériques ou bien un ABI JSON personalisé:',
            use_erc20_abi: 'Utiliser l\'ABI ERC20',
            use_erc721_abi: 'Utiliser l\'ABI ERC721',
            use_erc1155_abi: 'Utiliser l\'ABI ERC1155',
            abi_from_json: 'ABI depuis JSON',
            paste_abi_json_here: 'Coller votre ABI JSON ici',
            abi_json_preview: 'ABI JSON Prévisualisation',
            provided_abi_invalid: 'L\'ABI founni n\'est pas valide',
            provided_json_invalid: 'Le JSON fourni n\'est pas valide',
            read_functions: 'Fonctions de lecture',
            write_functions: 'Fonctions de d\'écriture',
            unverified_contract: 'Contract non verifié',
            verified_contract: 'Contract Verifié',
            upload_abi_json: 'Télécharger le Fichier JSON d\'ABI',
            discard_abi_json: 'Supprimer le JSON d\'ABI actuel',
        },
        header: {
            address_copied: 'Adresse copiée',
            address_not_found: 'La recherche d\'une adresse EVM lié à au compte natif { account } a échoué.',
            api_documentation: 'Documentation API',
            blockchain: 'Blockchain',
            blocks: 'Blocs',
            connect_wallet: 'Connecter Portefeuille',
            copy_address: 'Copier l\'adresse',
            csv_export: 'Exportation CSV',
            developers: 'Développeurs',
            disconnect_wallet_tooltip: 'Déconnecter le portefeuille',
            gas: 'Gas',
            goto_address_details: 'Voir les détails de l\'adresse',
            health_monitor: 'Moniteur de Santé',
            home: 'Accueil',
            more: 'Plus',
            network: 'Réseau',
            open_language_switcher: 'Ouvrir le sélécteur de language',
            search_failed: 'La recherche a échoué, veuillez saisir des mots clés de recherche corrects.',
            search_placeholder: 'Adresse, Tx, Bloc',
            select_language: 'Choisir votre language',
            switch_to_dark_theme: 'Passer au thème sombre',
            switch_to_light_theme: 'Passer au thème clair',
            system_token_price: 'Prix du {token}',
            telos_ecosystem: 'Écosystème Telos',
            telos_zero_explorer: 'Telos Zero Explorer',
            telos_evm_logo_alt: 'Logo de Telos EVM',
            telos_wallet: 'Wallet/Staking',
            telos_bridge: 'Bridge',
            transactions: 'Transactions',
            internal_transactions: 'Transactions internes',
            verify_contract_sourcify: 'Vérifier le Contrat (Sourcify)',
            view_other_networks: 'Voir d\'autres réseaux',
        },
        blocks: {
            block_height: 'Hauteur du bloc',
            timestamp: 'Horodatage',
            size: 'Taille',
            gas_used: 'Gaz utilisé',
            gas_limit: 'Limite de gaz',
            hash: 'Hachage',
            parent_hash: 'Hachage parent',
            nonce: 'Nonce',
            extra_data: 'Données supplémentaires',
            extra_data_tooltip: 'Données supplémentaires incluses dans le bloc',
            block_height_tooltip: 'Aussi appelé "Numéro de bloc". La hauteur du bloc représente la longueur de la chaîne de blocs, augmentant de un après l\'ajout d\'un nouveau bloc',
            timestamp_tooltip: 'Le moment et la date de production du bloc',
            transactions_tooltip: 'Le nombre de transactions dans le bloc. Les transactions internes sont des transactions qui se produisent à la suite de l\'exécution d\'un contrat et incluent une valeur TLOS',
            size_tooltip: 'la quantité de données dans le bloc, déterminée par la limite de gaz',
            gas_used_tooltip: 'La quantité de gaz utilisée dans le bloc',
            gas_limit_tooltip: 'La limite de gaz pour toutes les transactions dans le bloc',
            nonce_tooltip: 'La valeur utilisée lors du minage pour atteindre un consensus sur la Preuve de Travail pour le bloc',
            hash_tooltip: 'Le hachage de l\'en-tête du bloc',
            parent_hash_tooltip: 'Le hachage du bloc parent',
            count_transactions: '{count} transactions',
            count_transaction: '1 transaction',
            in_this_block: 'dans ce bloc',
            transactions: 'Transactions',
            transactions_root: 'Racine des Transactions',
            block: 'Bloc',
            age: 'Âge',
        },
        footer: {
            telos: 'Telos',
            telos_homepage: 'Page d\'accueil de Telos',
            telos_wallet: 'Portefeuille Telos',
            telos_bridge: 'Pont Telos',
            telos_zero_explorer: 'Telos Zero Explorer',
            buy_telos: 'Acheter Telos',
            stake_telos: 'Stakez vos TLOS',
            telos_ecosystem: 'Écosystème Telos',
            network_status: 'Statut du réseau',
            about: 'À propos',
            about_us: 'À propos de nous',
            contactUs: 'Contactez-nous',
            build: 'Construit avec',
            api_documentation: 'Documentation API',
            telos_documentation: 'Documentation Telos',
            github: 'Github',
            brand_assets: 'Ressources de marque',
            back_to_top: 'Retour en haut',
        },
        export: {
            block_range: 'Plage de Blocs',
            choose_download_option: 'Choisissez l\'option de téléchargement',
            column_header_action: 'Action',
            column_header_amount: 'Montant',
            column_header_block_number: 'Numéro de Bloc',
            column_header_contract_address: 'Adresse du Contrat',
            column_header_date: 'Date',
            column_header_from: 'De',
            column_header_nft_collection_name: 'Nom de la Collection NFT',
            column_header_nft_id: 'ID NFT',
            column_header_timestamp: 'Horodatage Unix (ms)',
            column_header_to: 'À',
            column_header_token_contract_address: 'Adresse du Contrat de Token',
            column_header_token_name: 'Nom du Token',
            column_header_token_symbol: 'Symbole du Token',
            column_header_tx_hash: 'Hash de la Transaction',
            date_range: 'Plage de Dates',
            start_date: 'Date de Début',
            end_date: 'Date de Fin',
            invalid_range: 'Plage invalide',
            download_csv: 'Télécharger CSV',
            download_transactions_csv: 'Télécharger Transactions CSV',
            download_erc_20_transfers_csv: 'Télécharger Transferts ERC-20 CSV',
            download_erc_721_transfers_csv: 'Télécharger Transferts ERC-721 CSV',
            download_erc_1155_transfers_csv: 'Télécharger Transferts ERC-1155 CSV',
            end_block: 'Bloc de Fin',
            erc_1155_transfers: 'Transferts ERC-1155',
            erc_20_transfers: 'Transferts ERC-20',
            erc_721_transfers: 'Transferts ERC-721',
            export_data: 'Exporter les Données',
            export_type: 'Type d\'Exportation',
            limit_notice: 'Seuls les {amount} premiers résultats seront exportés',
            notification_successful_download: 'Téléchargement réussi',
            notification_failed_download: 'Une erreur s\'est produite lors du téléchargement des données',
            page_header: 'Télécharger les Données (Exportation CSV)',
            reset: 'Réinitialiser',
            start_block: 'Bloc de Début',
            transactions: 'Transactions',
        },
    },
    evm_wallet: {
        send_icon_alt: 'Icône d\'envoi',
        receive_icon_alt: 'Icône de réception',
        buy_icon_alt: 'Icône d\'achat de plus de jetons',
        token_logo_alt: 'Logo du jeton',
        send: 'Envoyer',
        receive: 'Recevoir',
        scan_qr: 'Scannez le code QR pour obtenir votre compte',
        buy: 'Acheter',
        unwrap: 'Déballer',
        wrap: 'Emballer',
        stake: 'Staker',
        unstake: 'Déstaker',
        copy: 'Copier',
        link_to_send_aria: 'Lien vers la page d\'envoi',
        link_to_receive_aria: 'Lien vers la page de réception',
        link_to_buy_aria: 'Lien externe pour acheter des jetons',
        balance_row_actions_aria: 'Actions de la ligne de solde',
        no_fiat_value: 'Aucune valeur fiduciaire fiable trouvée',
        receiving_account: 'Compte de réception',
        account_required: 'Le compte est requis',
        token: 'Jeton',
        cancel: 'Annuler',
        estimated_fees: 'Frais estimés',
        amount_available: '{amount} disponible',
        amount_required: 'Le montant est requis',
        view_contract: 'Voir le contrat',
        invalid_form: 'Il y a au moins un champ invalide.',
        general_error: 'Une erreur s\'est produite lors du traitement de votre demande',
        invalid_amount_precision: 'Vous ne pouvez entrer que {precision} décimales',
        sent: 'Envoyé',
        received: 'Reçu',
        swapped: 'Échangé',
        switch: 'Changer',
        failed_contract_interaction: 'Interaction de contrat échouée',
        contract_interaction: 'Interaction de contrat',
        contract_creation: 'Création de contrat',
        aria_link_to_address: 'Lien vers la page d\'adresse de l\'explorateur de blocs',
        aria_link_to_transaction: 'Lien vers la page de transaction de l\'explorateur de blocs',
        click_to_fill_max: 'Cliquez pour remplir le montant maximum',
        amount_exceeds_available_balance: 'Solde insuffisant',
        incorrect_network: 'Réseau incorrect détecté! Passez à {networkName} pour terminer la transaction',
        viewing_n_transactions: 'Affichage de {rowsPerPage} sur {totalRows} transactions',
        no_transactions_found: 'Aucune transaction trouvée',
        add_to_metamask: 'Ajouter à MetaMask',
        rejected_metamask_prompt: 'L\'invitation MetaMask a été rejetée',
        error_adding_token_to_metamask: 'Erreur lors de l\'ajout du jeton à MetaMask',
        inventory: 'Objets de collection',
    },
    global: {
        all: 'tout',
        true: 'vrai',
        false: 'faux',
        close: 'Fermer',
        toggle_fullscreen: 'Basculer en mode plein écran',
        name: 'Nom',
        symbol: 'Symbole',
        show_table: 'Afficher le tableau',
        show_grid: 'Afficher la grille',
        records_per_page: 'Enregistrements par page:',
        max_decimals_reached: 'Veuillez utiliser au maximum {max} décimales',
        erc20_token: 'Jeton ERC20',
        erc721_token: 'Jeton ERC721',
        sign: 'Signer',
        hours: 'heure(s)',
        updated: 'Dernière mise à jour',
        action: 'Action',
        wallet_response: 'En attente de réponse de votre fournisseur de portefeuille',
        minutes: 'minute(s)',
        days: 'jour(s)',
        and: 'et',
        unknown: 'Inconnu',
        data_behind_by: 'Désolé! Les données affichées sont en retard de',
        not_synced: 'L\'indexeur n\'est pas synchronisé avec le dernier bloc',
        try_reloading: 'Vous pouvez essayer de recharger la page pour résoudre le problème. Si le problème persiste, veuillez contacter un membre de l\'équipe Telos.',
        error: 'Erreur',
        async_error_description: 'Désolé! Une erreur s\'est produite lors de la demande de ressources. Veuillez vérifier que votre connexion Internet fonctionne et réessayez. Si l\'erreur persiste, contactez-nous directement sur notre canal Telegram.',
        language: 'Langue',
        cancel: 'Annuler',
        ok: 'D\'accord',
        dismiss: 'Rejeter',
        empty_block: 'Bloc vide',
        empty_block_description: 'Ce bloc n\'a pas de transactions. Essayez de naviguer vers un bloc différent',
        internal_error: 'Erreur Interne',
    },
    layouts: {
        health_status: 'Statut santé',
        stake_telos: 'Stakez vos TLOS',
        teloscan_mainnet: 'Teloscan Mainnet',
        teloscan_testnet: 'Teloscan Testnet',
    },
    notification: {
        success_title_trx: 'Succès',
        success_title_copied: 'Copié',
        success_message_trx: 'Votre transaction a été traitée. Pour obtenir des informations détaillées, cliquez sur le lien ci-dessous.',
        success_message_revoking: 'La révocation de l\'allocation de <b>{symbol}</b> pour <b>{address}</b> a été effectuée avec succès.',
        success_message_copied: 'Le nom de votre compte a été copié dans le presse-papiers.',
        success_see_trx_label: 'Voir Transaction',
        dismiss_label: 'Rejeter',
        error_title: 'Oups !',
        error_title_disconnect: 'Pas de connexion Internet',
        error_message_disconnect: 'Désolé, il semble que vous ne soyez pas connecté à Internet. Veuillez vérifier votre connexion réseau et réessayer.',
        error_see_details_label: 'Voir Détails',
        error_details_title: 'Détails de l\'Erreur',
        neutral_message_sending: 'Envoi de <b>{quantity}</b> à <b>{address}</b>',
        neutral_message_revoking: 'Révocation de l\'allocation de <b>{symbol}</b> pour <b>{address}</b>',
        neutral_message_wrapping: 'Conversion de <b>{quantity} {symbol}</b> en ERC20',
        neutral_message_unwrapping: 'Restauration de <b>{quantity} {symbol}</b>',
        neutral_message_withdrawing: 'Retrait de <b>{quantity} {symbol}</b>',
        dont_show_message_again: 'Ne plus afficher ce message',
        error_message_sending: 'Une erreur s\'est produite lors de l\'envoi de <b>{quantity}</b> à <b>{address}</b>',
        error_message_revoking: 'Une erreur s\'est produite lors de la révocation de l\'allocation de <b>{symbol}</b> pour <b>{address}</b>',
        error_message_wrapping: 'Une erreur s\'est produite lors de la conversion de <b>{quantity} {symbol}</b> en ERC20',
        error_message_unwrapping: 'Une erreur s\'est produite lors de la restauration de <b>{quantity} {symbol}</b>',
        error_message_withdrawing: 'Une erreur s\'est produite lors du retrait de <b>{quantity} {symbol}</b>',
        error_message_custom_call: 'Une erreur s\'est produite lors de l\'appel à <b>{name}</b> avec <b>{params} paramètres</b>',
        error_message_custom_call_send: 'Une erreur s\'est produite lors de l\'appel à <b>{name}</b> avec <b>{params} paramètres</b> et l\'envoi de <b>{quantity} {symbol}</b>',
        neutral_message_custom_call: 'Appel à <b>{name}</b> avec <b>{params} paramètres</b>',
        neutral_message_custom_call_send: 'Appel à <b>{name}</b> avec <b>{params} paramètres</b> et envoi de <b>{quantity} {symbol}</b>',
    },
    core: {
        contracts: {
            invalid_contract: 'Contrat ou ABI du contrat manquant',
            contract_data_required: 'Données du contrat manquantes',
        },
        evm: {
            error_support_provider_request: 'Le fournisseur ne supporte pas la méthode de demande',
            error_login: 'Erreur lors du processus de connexion',
            error_add_chain_rejected: 'L\'utilisateur a rejeté la demande d\'ajout de la chaîne',
            error_connect_rejected: 'L\'utilisateur a rejeté la demande de connexion à la chaîne',
            error_add_chain: 'Erreur lors de l’ajout de la chaîne',
            error_switch_chain_rejected: 'L\'utilisateur a rejeté la demande de changement de chaîne',
            error_switch_chain: 'Erreur lors du changement de chaîne',
            error_no_provider: 'Aucun fournisseur trouvé',
            error_getting_function_interface: 'Erreur lors de la recherche de la signature de l’événement pour la fonction {prefix}',
            error_getting_event_interface: 'Erreur lors de la recherche de la signature de l’événement pour l’événement {hex}',
            error_invalid_address: 'Adresse invalide',
            error_getting_contract_creation: 'Erreur lors de l\'obtention des informations de création du contrat',
            error_no_signer: 'Aucun signataire trouvé',
            error_send_transaction: 'Une erreur inconnue s’est produite lors de l’envoi de la transaction',
            error_transfer_failed: 'Une erreur inconnue s’est produite lors du transfert de tokens',
            error_call_exception: 'Une erreur s’est produite lors de l’appel de la fonction du contrat intelligent',
            error_insufficient_funds: 'Vous n\'avez pas assez de fonds pour compléter cette transaction',
            error_missing_new: 'Un nouvel opérateur manquait lors du déploiement du contrat intelligent',
            error_nonce_expired: 'Le nonce utilisé pour cette transaction est trop bas',
            error_numeric_fault: 'Une opération numérique a échoué',
            error_replacement_underpriced: 'Une nouvelle transaction remplaçant une transaction existante n’a pas un prix de gaz suffisant',
            error_transaction_replaced: 'Cette transaction a été remplacée par une autre transaction',
            error_unpredictable_gas_limit: 'La limite de gaz pour cette transaction n’a pas pu être estimée',
            error_user_rejected: 'Vous avez rejeté la transaction',
            error_transaction_canceled: 'Vous avez annulé l’action',
            error_wrap_not_supported_on_native: 'Le wrapping n’est pas supporté sur la chaîne native',
            error_unwrap_not_supported_on_native: 'Le unwrapping n’est pas supporté sur la chaîne native',
            error_wrap_failed: 'Une erreur inconnue s’est produite lors du wrapping des tokens',
            error_unwrap_failed: 'Une erreur inconnue s’est produite lors du unwrapping des tokens',
            error_withdraw_failed: 'Une erreur inconnue s’est produite lors du retrait des tokens',
            error_fetching_token_price: 'Une erreur inconnue s’est produite lors de la récupération des données de prix des tokens',
        },
        history: {
            error_fetching_transactions: 'Erreur inattendue lors de la récupération des transactions. Veuillez actualiser la page pour réessayer.',
            error_fetching_nft_transfers: 'Erreur inattendue lors de la récupération des transferts de NFT. Veuillez actualiser la page pour réessayer.',
        },
        chain: {
            error_update_data: 'Erreur lors de la mise à jour des données',
            error_apy: 'Erreur lors de l\'obtention du APY',
            error_invalid_network: 'Réseau invalide',
            error_no_default_authenticator: 'Aucun authentificateur par défaut trouvé',
        },
        account: {
            error_login_native: 'Une erreur est survenue lors de la tentative de connexion à la chaîne native',
            error_login_evm: 'Une erreur est survenue lors de la tentative de connexion à la chaîne EVM',
            error_auto_login: 'Une erreur est survenue lors de la tentative de connexion automatique de l\'utilisateur',
        },
        utils: {
            error_parsing_transaction: 'Échec de l\'analyse des données de transaction',
            error_contract_instance: 'Échec de la création de l\'instance de contrat',
            error_parsing_log_event: 'Échec de l\'analyse de l\'événement de journal',
        },
        balances: {
            error_at_transfer_tokens: 'Une erreur est survenue lors de la tentative de transfert de jetons',
            error_token_contract_not_found: 'Contrat de jeton introuvable pour l\'adresse {address}',
        },
        rex: {
            error_contract_not_found: 'Contrat introuvable pour l\'adresse {address}',
        },
        wallets: {
            error_system_token_transfer_config: 'Erreur lors de l\'obtention de la configuration de transfert de jetons du système Wagmi',
            error_token_transfer_config: 'Erreur lors de l\'obtention de la configuration de transfert de jetons Wagmi',
            error_oreid_no_chain_account: 'L\'application {appName} n\'a pas de compte de chaîne pour la chaîne {networkName}',
            network_switch_success: 'Réseau changé avec succès',
        },
        wrap: {
            error_getting_wrapped_contract: 'Une erreur est survenue lors de l\'obtention du contrat de jeton du système enveloppé',
            error_wrap: 'Une erreur inconnue est survenue lors de l\'enveloppement du jeton du système',
        },
        words: {
            day: 'jour',
            days: 'jours',
            hour: 'heure',
            hours: 'heures',
            minute: 'minute',
            minutes: 'minutes',
            second: 'seconde',
            seconds: 'secondes',
            time_ago: 'Il y a {time}',
        },
    },
};
